<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ $lang.labels.account }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">{{ $lang.labels.yourAccount }}</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-row v-if="err || success" dense no-gutters class="px-3">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-card-text>
        <v-text-field v-model="formData.email" label="Email" outlined @keyup.enter="submit()"></v-text-field>
        <v-text-field
          v-model="formData.captchaAnswer"
          label="Captcha"
          outlined
          hide-details
          @keyup.enter="submit()"
        ></v-text-field>
        <div style="position: relative">
          <v-img :src="`data:image/png;base64, ${captchaBE.image}`" class="mt-3"></v-img>
          <v-btn
            icon
            color="primary"
            text
            :disabled="loadingCaptcha"
            large
            absolute
            style="bottom: 2px; right: 2px;"
            @click="getCaptcha()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <v-btn
          block
          class="accent mt-4 color-accent-text"
          x-large
          :disabled="loadingLogin || !formData.email || !formData.captchaAnswer"
          :loading="loadingLogin"
          @click="submit()"
        >Reset</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import {
  forgotPasswordUsingPOST as forgotPassword,
  captchaCreateUsingGET as captchaCreate
} from '@/utils/api'

export default {
  data() {
    return {
      loadingCaptcha: false,
      loadingLogin: false,
      formData: {
        email: '',
        captchaAnswer: '',
        captchaKey: ''
      },
      captchaBE: {
        key: '',
        image: ''
      },
      err: '',
      success: ''
    }
  },
  created() {
    this.getCaptcha()
  },
  methods: {
    submit() {
      this.err = ''
      this.loadingLogin = true
      // Check if email/Password are empty
      if (this.formData.email.length === 0) {
        this.err = this.$lang.errors.missingEmail
        this.loadingLogin = false

        return
      }
      if (this.formData.captchaAnswer.length === 0) {
        this.err = this.$lang.errors.missingCaptcha
        this.loadingLogin = false

        return
      }
      this.formData.email = this.formData.email.toLowerCase().trim()
      // Authenticate user
      forgotPassword({ request: this.formData })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {

            this.loadingLogin = false
            this.success = this.$lang.success.passwordReset

            setTimeout(() => {
              window.open('/login','_self')
            }, 4000)

            return
          }
          this.loadingLogin = false
          this.err = res.data.statusText
        })
        .catch((err) => {
          console.log(err)
          this.err = this.$lang.errors.somethingWentWrong
          this.loadingLogin = false
        })

    },
    getCaptcha() {
      this.loadingCaptcha = true
      captchaCreate()
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.captchaBE = res.data.data
            this.formData.captchaKey = res.data.data.key
            this.loadingCaptcha = false

            return
          }
          this.err = res.data.statusText
          this.loadingCaptcha = false
        })
        .catch((err) => {
          console.log(err)
          this.err = this.$lang.errors.somethingWentWrong
          this.loadingCaptcha = false
        })
    }
  }
}
</script>
